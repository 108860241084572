//test push
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Textfield from "../Textfield";
import DropDownMenu from "../DropDownMenu";
import "react-phone-number-input/style.css";

import { useTranslation } from "react-i18next";
import { getCategory } from "../../HelperFn/getCategory";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import classes from "../../Styles/form.module.css";
import SaudiFlag from "../../assets/saudi-arabia.png";
import newFlag from "../../assets/new.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./forms.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ar from "date-fns/locale/ar";
import { useCallback } from "react";
import NewMultiSelect from "../NewMultiselect";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Form = ({ direction, data }) => {
        useEffect(() => {
          // Setting locale dynamically based on direction
          const locale = direction === "ar" ? ar : null; // Default to null for English
          if (locale) {
            registerLocale("ar", ar);
          }
          setDefaultLocale(locale ? "ar" : null);
        }, [direction]);

        const [isNameValid, setIsNameValid] = useState(true);
        const validateName = useCallback(
          (name) => {
            let arabicAndEnglishPattern;
            if (direction === "ar") {
              console.log("arbic");
              arabicAndEnglishPattern = /^[\u0600-\u06FF\s]+$/;
            } else if (direction === "en") {
              console.log("englsh");
              arabicAndEnglishPattern = /^[a-zA-Z\s]+$/;
            }

            // Define a regular expression pattern for letters and spaces

            // Check if the input matches the pattern
            const isValidName = arabicAndEnglishPattern.test(name.trim());
            let isValidLength = false;
            if (name.length <= 30 && name.length >= 3) {
              isValidLength = true;
            }
            if (isValidName === true && isValidLength === true) {
              setIsNameValid(true);
            } else {
              setIsNameValid(false);
            }
          },
          [direction, setIsNameValid]
        );

        //const id=useParams()
        var idnow;
        const hash = window.location.hash;
        const variables = hash.substring(2); // Remove the initial '/#'
        // console.log(variables);
        if (variables == null) {
          // console.log("hi undefiend");
          idnow = null;
        } else {
          idnow = variables;
          // console.log(variables);
        }

        // Responsive Design
        const theme = useTheme();
        const match = useMediaQuery(theme.breakpoints.down("md"));

        // Get The Language from Local Storage
        const { t } = useTranslation();

        const [phoneValue, setPhoneValue] = useState("");
        const [isValid, setIsValid] = useState(true);

        const arabicNumerals = {
          "0": "٠",
          "1": "١",
          "2": "٢",
          "3": "٣",
          "4": "٤",
          "5": "٥",
          "6": "٦",
          "7": "٧",
          "8": "٨",
          "9": "٩",
        };

        function checkArabicNumerals(str) {
          for (let i = 0; i < str.length; i++) {
            let isArabicNumeral = false;
            for (const numeral in arabicNumerals) {
              if (arabicNumerals[numeral] === str[i]) {
                isArabicNumeral = true;
                break;
              }
            }
            if (!isArabicNumeral) {
              return false; // If any character is not an Arabic numeral, return false
            }
          }
          return true; // If all characters are Arabic numerals, return true
        }

        // Example usage:

        const convertToArabic = (number) => {
          const arabicNumerals = {
            "0": "٠",
            "1": "١",
            "2": "٢",
            "3": "٣",
            "4": "٤",
            "5": "٥",
            "6": "٦",
            "7": "٧",
            "8": "٨",
            "9": "٩",
          };

          return number
            .split("")
            .map((digit) => arabicNumerals[digit] || digit)
            .join("");
        };

        const validatePhoneNumber = (e) => {
          const inputPhoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
          // Define a regular expression pattern for Saudi Arabia phone numbers
          const saudiPhoneNumberPattern = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7|2)([0-9]{7})$/;

          // Check if the input matches the pattern

          // Convert only the newly added digits to Arabic numerals
          const arabicDigits = convertToArabic(e.target.value.slice(-1));
          const newPhoneValue = e.target.value.slice(0, -1) + arabicDigits;

          let isValidPhoneNumber;

          if (direction === "en") {
            setPhoneValue(inputPhoneNumber);
            isValidPhoneNumber = saudiPhoneNumberPattern.test(inputPhoneNumber);
          } else {
            setPhoneValue(newPhoneValue);
            // console.log(newPhoneValue.length);
            if (
              newPhoneValue.length === 9 &&
              newPhoneValue[0] == "٥" &&
              checkArabicNumerals(newPhoneValue)
            ) {
              console.log("valid");
              isValidPhoneNumber = true;
            }
            // console.log(newPhoneValue);
          }
          setIsValid(isValidPhoneNumber);
        };

        const validatePhoneNum = (phone) => {
          const inputPhoneNumber = phone.replace(/\D/g, ""); // Remove non-numeric characters
          // Define a regular expression pattern for Saudi Arabia phone numbers
          const saudiPhoneNumberPattern = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
          // Check if the input matches the pattern
          const isValidPhoneNumber = saudiPhoneNumberPattern.test(
            inputPhoneNumber
          );
          setPhoneValue(inputPhoneNumber);

          setIsValid(isValidPhoneNumber);
        };

        console.log(phoneValue);

        const [textfieldValues, setTextfieldValues] = useState({
          name: "",
        });
        const [gender, setGender] = useState("");
        const [errors, setErrors] = useState({});
        const [captcha, setRecaptcha] = useState("");
        const [maxLength, setMaxlength] = useState("");

        const [dropdownValues, setDropdownValues] = useState({
          city: "",
          clinic: "",
          service: "",
          nearestBranch: "",
          communicationTime: "",
          knowAboutus: "",
        });

        const handleDropdownSelect = (name, value) => {
          setDropdownValues((prevValues) => ({
            ...prevValues,
            [name]: value,
          }));
        };

        const handleTextFieldValues = (name, value) => {
          // Validate the name when it changes
          if (name === "name") {
            validateName(value);
          }
          setTextfieldValues((prevValues) => ({
            ...prevValues,
            [name]: value,
          }));
        };

        const handleCaptcha = (value) => {
          setRecaptcha(value);
        };

        const [serviceData, setServiceData] = useState([]);
        const [clinicsData, setClinicsData] = useState([]);
        const [isLoading, setLoading] = useState(true);
        const [startDate, setStartDate] = useState(null);
        const [resetDropdown, setResetDropdown] = useState(false);

        const datePickerRef = useRef(null);

        const openDatePicker = () => {
          // Use the focus method to open the DatePicker
          if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);
          }
        };
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(
                `${baseUrl}/Menu/Services/${dropdownValues?.clinic}`
              );

              if (response.ok) {
                const data = await response.json();
                setServiceData(data);
                setLoading(false);
              } else {
                console.error("Response not OK");
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          fetchData();
        }, [dropdownValues?.clinic]);

        useEffect(() => {
          const fetchData = async () => {
            // console.log(dropdownValues?.city);
            try {
              const response = await fetch(
                `${baseUrl}/Menu/Clinics/${dropdownValues?.city}`
              );

              if (response.ok) {
                const data = await response.json();
                setClinicsData(data);
                setLoading(false);
              } else {
                console.error("Response not OK");
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          fetchData();
        }, [dropdownValues?.city]);

        // Update error messages when direction changes

        const handleSubmit = async (e) => {
          e.preventDefault();

          // Validate the name before submitting the form
          validateName(textfieldValues.name);
          console.log("waryny al name kdh", textfieldValues.name);
          validatePhoneNum(phoneValue);

          const data = {
            name: textfieldValues.name,
            // birthDate: formatDate("6/12/2001"),
            birthDate: startDate,
            gender: gender,
            direction: direction,
            phoneNumber: phoneValue,
            cityId: dropdownValues.city,
            clinicId: dropdownValues.clinic,
            serviceId: dropdownValues.service,
            nearestBranchId: dropdownValues.nearestBranch,
            communicationTimeId: dropdownValues.communicationTime,
            knowAboutusId: dropdownValues.knowAboutus,
          };

          console.log(data);

          const newErrors = {};
          Object.entries(data).forEach(([key, value]) => {
            if (value === "" || value === null) {
              newErrors[key] =
                direction === "en" ? "Field Required" : "حقل الزامي";
              console.log("yarb", newErrors[key]);
            }
          });
          setErrors(newErrors);

          if (Object.keys(newErrors).length > 0) {
            // Scroll to the first error
            const firstErrorKey = Object.keys(newErrors)[0];
            const element = document.getElementsByName(firstErrorKey)[0];
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            return; // Stop form submission if there are errors
          }

          if (
            Object.keys(newErrors).length === 0 &&
            isNameValid === false &&
            isValid === false
          ) {
            const element = document.getElementsByName("name")[0];
            console.log("element", element);
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }

          if (
            Object.keys(newErrors).length === 0 &&
            isNameValid === true &&
            isValid === false
          ) {
            const element = document.getElementsByName("phoneNumber")[0];
            console.log("element", element);
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }

          const marketeriddata = {
            PatientMarkterId: null,
            MarkterId: idnow,
          };

          if (
            Object.keys(newErrors).length === 0 &&
            isNameValid === true &&
            isValid === true
          ) {
            if (direction === "en") {
              data.phoneNumber = `+966${data.phoneNumber}`;
            } else {
              data.phoneNumber = `+٩٦٦${data.phoneNumber}`;
            }
            console.log("waryny al data kdh", data);
            const res = await fetch(`${baseUrl}/Patient/Patient`, {
              method: "POST",
              body: JSON.stringify(data),
              headers: { "Content-Type": "application/json" },
            }).then(async (response) => {
              const { data } = await axios.get(
                `https://clinicapi.mygatein.com/Markter/Form/GetMarkterId?iddata=${JSON.stringify(
                  marketeriddata
                )}`
              );
            });

            // Reset dropdown by changing the 'reset' prop
            setResetDropdown(!resetDropdown);

            setPhoneValue("");
            setGender("");
            setDropdownValues({
              city: "",
              clinic: "",
              service: "",
              nearestBranch: "",
              communicationTime: "",
              knowAboutus: "",
            });
            setStartDate(null);
            setTextfieldValues({
              name: "",
            });
            toast.success(t("Data Has Been Sent Successfully"), {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          // setErrorMessage(t("The number must start with 5 and be 9 digits"))
        };

        useEffect(() => {
          validateName(textfieldValues.name);
        }, [textfieldValues.name, validateName]);

        // console.log(textfieldValues.name);

        // const dataTest=[{},{}]

        //       <NewMultiSelect
        //       data={
        //         dataTest &&
        //         dataTest.map((el) => ({
        //           ...el,
        //           label: el.ProductCategoriesNameEng,
        //           value: el.ProductCategoriesId,
        //         }))
        //       }
        //       onSelectionChange={(e) => {
        //         console.log("test", e);
        //       }}
        //       defaultValue={{ label: "ASS test", value: "1" }}
        //     />}

        function updateObjectValues(obj, direction) {
          // Define the text based on the direction
          const newText = direction === "en" ? "Field Required" : "حقل الزامي";

          // Loop through each property in the object
          for (let key in obj) {
            // Update the value of each property
            obj[key] = newText;
          }

          return obj;
        }

        // useEffect(() => {
        //   console.log(errors); // This will log the previous state
        //   setErrors((prevErrors) => updateObjectValues(prevErrors, direction));
        //   console.log(errors); // This will still log the previous state due to closure
        // }, [direction, errors]);

        const [directionChanged, setDirectionChanged] = useState(false);

        useEffect(() => {
          if (directionChanged) {
            const updatedErrors = { ...errors };
            Object.keys(updatedErrors).forEach((key) => {
              updatedErrors[key] =
                direction === "en" ? "Field Required" : "حقل الزامي";
            });
            setErrors(updatedErrors);
          } else {
            setDirectionChanged(true);
          }
        }, [direction]);

        useEffect(() => {
          if (resetDropdown === true) {
            setResetDropdown(false);
          }
        }, [resetDropdown]);

        useEffect(() => {
          if (directionChanged) {
            setResetDropdown(!resetDropdown);

            setPhoneValue("");
            setGender("");
            setDropdownValues({
              city: null,
              clinic: null,
              service: null,
              nearestBranch: null,
              communicationTime: null,
              knowAboutus: null,
            });
            setStartDate(null);
            setTextfieldValues({
              name: "",
            });
          } else {
            setDirectionChanged(true);
            console.log("directionChanged", "f");
          }
        }, [directionChanged, direction]);

        function convertToArabicNumerals(westernNumerals) {
          const westernDigits = "0123456789";
          const arabicDigits = "٠١٢٣٤٥٦٧٨٩";

          // Create a map of Western digits to Arabic digits using Unicode offset
          const digitMap = {};
          for (let i = 0; i < westernDigits.length; i++) {
            digitMap[westernDigits.charAt(i)] = arabicDigits.charAt(i);
          }

          // Replace each Western digit in the input string with its Arabic counterpart
          const arabicNumerals = westernNumerals.replace(/[0-9]/g, (digit) => {
            return digitMap[digit];
          });

          return arabicNumerals;
        }

        const locale = direction === "ar" ? ar : null;
        registerLocale("ar", ar);
        registerLocale("en", null);
        setDefaultLocale(locale);

        const renderDayContents = (day) => {
          // Determine the numeral conversion function based on direction
          const numeralConverter =
            direction === "ar"
              ? convertToArabicNumerals
              : (num) => num.toString();

          const arabicDay = numeralConverter(day.toString()); // Convert day value to Arabic or leave as English
          return <div className="day-contents">{arabicDay}</div>;
        };

function formatDate(inputDate, direction = 'en') {
    let convertedDate = inputDate;

    // Convert Arabic numerals to English numerals if direction is 'ar'
    if (direction === 'ar') {
        convertedDate = inputDate.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(match) {
            return '0123456789'['٠١٢٣٤٥٦٧٨٩'.indexOf(match)];
        });
    }

    // Split the converted input date string by '/'
    const parts = convertedDate.split('/');

    // Extract day, month, and year from parts
    const day = parseInt(parts[0], 10); // Convert to integer
    const month = parseInt(parts[1], 10); // Convert to integer
    const year = parseInt(parts[2], 10); // Convert to integer

    // Create a new Date object using UTC methods
    const dateObject = new Date(Date.UTC(year, month - 1, day));

    // Get the ISO date string in UTC format
    const isoDateString = dateObject.toISOString();

    // Return the ISO date string
    return isoDateString;
}

// Example usage:
const arabicDate = '١٤/٥/٢٠٢٠'; // Arabic-formatted date
const englishDate = '14/5/2020'; // English-formatted date

const formattedDateArabic = formatDate(arabicDate, 'ar');
console.log(formattedDateArabic); // Output: "2020-05-13T00:00:00.000Z"

const formattedDateEnglish = formatDate(englishDate);
console.log(formattedDateEnglish); // Output: "2020-05-13T00:00:00.000Z"


        return (
          <section
            className={classes.container}
            style={{ direction: `${direction === "ar" ? "rtl" : "ltr"}` }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <Textfield
                    direction={direction}
                    value={textfieldValues.name}
                    name="name"
                    type="text"
                    label={t("Name")}
                    placeholder={t("Name")}
                    width="100%"
                    getInput={(value) => handleTextFieldValues("name", value)}
                    errors={errors}
                    inputprops={{ maxLength: 30 }}
                    isName={true}
                  />

                  {!isNameValid && textfieldValues.name.length !== 0 && (
                    <p
                      style={{
                        color: "red",
                        direction: direction === "ar" ? "rtl" : "ltr",
                        fontSize: "13px",
                        margin:
                          direction === "ar" ? "0 10px 0 0" : "0 0 0 10px",
                      }}
                    >
                      {t("Invalid Name")}
                    </p>
                  )}
                </Box>

                {/* Phone Number & Birth Date */}
                <section className={classes.splitTwoInputs}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      style={{
                        fontSize: `${match ? "16px" : "20px"}`,
                        marginLeft: "5px",
                      }}
                    >
                      {t("Phone Number")}
                    </label>
                    <TextField
                      variant="outlined"
                      value={phoneValue}
                      name="phoneNumber"
                      onChange={validatePhoneNumber}
                      InputProps={{
                        startAdornment: (
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "0",
                              borderRight:
                                direction === "en"
                                  ? "1px solid black"
                                  : "1px solid black",

                              marginRight: "10px",
                            }}
                          >
                            <img
                              alt="Saudi Arabia Flag"
                              src={newFlag}
                              style={{
                                width: "32px",
                                height: "24px",
                                marginRight:
                                  direction === "en" ? "10px" : "10px",
                              }}
                            />
                            <Typography
                              style={{
                                paddingRight:
                                  direction === "en" ? "10px" : "10px",
                              }}
                            >
                              {direction === "en" ? "+966" : "+٩٦٦"}
                            </Typography>
                          </Box>
                        ),
                      }}
                      style={{
                        marginTop: "5px",
                        backgroundColor: "white",
                        fontSize: "15px",
                        direction: direction === "ar" ? "ltr" : "ltr",
                      }}
                    ></TextField>

                    <Typography
                      style={{
                        direction: direction === "ar" ? "rtl" : "ltr",
                        fontSize: "12px",
                        margin:
                          direction === "ar" ? "2px 10px 0 0" : "2px 0 0 10px",
                        color: "#8b6767",
                      }}
                    >
                      {t("The number must start with 5 and be 9 digits")}
                    </Typography>

                    {errors?.phoneNumber && (
                      <p
                        style={{
                          color: "red",
                          direction: direction === "ar" ? "rtl" : "ltr",
                          fontSize: "13px",
                          margin:
                            direction === "ar" ? "0 10px 0 0" : "0 0 0 10px",
                        }}
                      >
                        {errors.phoneNumber}
                      </p>
                    )}

                    {!isValid && phoneValue !== "" && (
                      <p
                        style={{
                          color: "red",
                          direction: direction === "ar" ? "rtl" : "ltr",
                          fontSize: "13px",
                          margin:
                            direction === "ar"
                              ? "3px 10px 10px 0"
                              : "3px 0 10px 10px",
                        }}
                      >
                        {t("Invalid Phone Number")}
                      </p>
                    )}
                  </div>

                  {/*birth day */}
                  <Box className={classes.resDate}>
                    <label
                      style={{
                        fontSize: `${match ? "16px" : "20px"}`,
                        marginLeft: "5px",
                      }}
                    >
                      {t("Birth date")}
                    </label>

                    <Box
                      className={`${
                        direction === "ar" ? "arabic-select" : "english-select"
                      }`}
                    >
                      <CalendarMonthIcon
                        style={{ cursor: "pointer" }}
                        className="my-calender"
                        onClick={openDatePicker}
                      />
                      <DatePicker
                        ref={datePickerRef}
                        name="birthDate"
                        todayButton={direction === "ar" ? "اليوم" : "Today"} // Use translation function for "Today"
                        selected={startDate}
                        onChange={(date) => {
                          console.log(date);
                          setStartDate(date);
                        }}
                        placeholderText={
                          direction === "ar"
                            ? "يوم / شهر / سنة"
                            : "dd / mm / yyyy"
                        }
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        locale={locale}
                        dayClassName={(date) => "custom-day"} // Custom class for day element
                        renderDayContents={(day) => renderDayContents(day)} // Custom render for day contents based on direction
                      />
                    </Box>

                    {errors?.birthDate && (
                      <p
                        style={{
                          color: "red",
                          direction: direction === "ar" ? "rtl" : "ltr",
                          fontSize: "13px",
                          margin:
                            direction === "ar"
                              ? "5px 10px 20px 0"
                              : "5px 0 20px 10px",
                        }}
                      >
                        {errors.birthDate}
                      </p>
                    )}
                  </Box>
                </section>

                {/* Male OR Female */}
                <Box style={{ marginTop: "15px" }}>
                  <label style={{ fontSize: "20px", marginLeft: "5px" }}>
                    {t("Gender")}
                  </label>
                  <Box
                    style={{
                      width: "100%",
                      background: "white",
                      marginTop: "5px",
                      padding: "20px",
                      borderRadius: "5px",
                      marginBottom: errors?.gender ? "0" : "20px",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        style={{ margin: "0" }}
                        value={t("Female")}
                        control={<Radio />}
                        label={t("Female")}
                      />
                      <FormControlLabel
                        style={{ margin: "0" }}
                        value={t("Male")}
                        control={<Radio />}
                        label={t("Male")}
                      />
                    </RadioGroup>
                  </Box>

                  {errors?.gender && (
                    <p
                      style={{
                        color: "red",
                        direction: direction === "ar" ? "rtl" : "ltr",
                        fontSize: "13px",
                        margin:
                          direction === "ar"
                            ? "5px 10px 20px 0"
                            : "5px 0 20px 10px",
                      }}
                    >
                      {errors.gender}
                    </p>
                  )}
                </Box>

                {/* Drop Down Menus */}
                <section className={classes.dropDownMenus}>
                  {/* done */}
                  <DropDownMenu
                    direction={direction}
                    value={dropdownValues.city}
                    label={t("City")}
                    data={getCategory(data, 3, direction)}
                    //data={clincs}
                    onSelect={(id) => handleDropdownSelect("city", id)}
                    name="cityId"
                    errors={errors}
                    reset={resetDropdown}
                  />

                  {/* done */}
                  <DropDownMenu
                    direction={direction}
                    value={dropdownValues.clinic}
                    label={t("Choose the clinic")}
                    // data={getCategory(data, 2, direction)}
                    data={clinicsData}
                    // data={clincs}
                    onSelect={(id) => handleDropdownSelect("clinic", id)}
                    name="clinicId"
                    errors={errors}
                    reset={resetDropdown}
                    disabled={!dropdownValues.city} // Disable if the city is not selected
                  />

                  {/* done */}
                  <DropDownMenu
                    direction={direction}
                    value={dropdownValues.service}
                    label={t("Select the service")}
                    data={serviceData}
                    loading={isLoading}
                    onSelect={(id) => handleDropdownSelect("service", id)}
                    name="serviceId"
                    errors={errors}
                    reset={resetDropdown}
                    disabled={!dropdownValues.clinic} // Disable if the city is not selected
                  />

                  {/* done */}
                  <DropDownMenu
                    direction={direction}
                    value={dropdownValues.nearestBranch}
                    label={t("Your nearest branch")}
                    // data={clincs}
                    // data={getCategory(data, 4, direction)}
                    data={clinicsData}
                    onSelect={(id) => handleDropdownSelect("nearestBranch", id)}
                    name="nearestBranchId"
                    errors={errors}
                    reset={resetDropdown}
                    disabled={!dropdownValues.service} // Disable if the city is not selected
                  />

                  {/* done */}
                  <DropDownMenu
                    direction={direction}
                    value={dropdownValues.communicationTime}
                    label={t("Preferred contact times")}
                    data={getCategory(data, 6, direction)}
                    //data={clincs}
                    onSelect={(id) =>
                      handleDropdownSelect("communicationTime", id)
                    }
                    name="communicationTimeId"
                    reset={resetDropdown}
                    errors={errors}
                  />

                  {/* done */}
                  <DropDownMenu
                    direction={direction}
                    value={dropdownValues.knowAboutus}
                    label={t("How did you get to know us?")}
                    data={getCategory(data, 5, direction)}
                    //data={clincs}
                    onSelect={(id) => handleDropdownSelect("knowAboutus", id)}
                    name="knowAboutusId"
                    reset={resetDropdown}
                    errors={errors}
                  />
                </section>

                <div className={classes.captcha}>
                  <ReCAPTCHA
                    key={direction}
                    hl={direction === "ar" ? "ar" : "en"}
                    sitekey="6LciKMMnAAAAANO9jVtATho3jqInfT1mckWXofyu"
                    onChange={handleCaptcha}
                  />
                </div>

                <section className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    disabled={!captcha}
                  >
                    {t("Send")}
                  </Button>
                </section>
              </Box>
            </Box>
            <ToastContainer />
          </section>
        );
      };;

export default Form;
