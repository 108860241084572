import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./header.css";
import {
  Container,
  Select,
  MenuItem,
  Typography,
  FormControl,
} from "@mui/material";

import Logo from "../assets/logo.jpg";

import EnglandFlag from "../assets/united-kingdom.png";
import SaudiFlag from "../assets/saudi-arabia.png";
import newflag from "../assets/new.png";
import { useTranslation } from "react-i18next";
import NewMultiSelect from "../Components/NewMultiselect";
const Header = ({ direction, setDirection }) => {

  const options = [
    { value: "en", label: "English", imageUrl: EnglandFlag },
    { value: "ar", label: "العربية", imageUrl: newflag },
  ];
  const lang = localStorage.getItem("lang");


  function getDefaultOptionByLanguage(language) {
    let hasReturnedDefaultOption = false;
  
    function filterAndReturnDefaultOption(lang) {
      const filteredOptions = options.filter(option => option.value === lang);
      if (filteredOptions.length > 0) {
        hasReturnedDefaultOption = true;
        return {
          label: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <img
                src={filteredOptions[0].imageUrl}
                alt={filteredOptions[0].label}
                style={{ width: "25px", height: "auto", marginRight: "8px" }}
              />
              <span>{filteredOptions[0].label}</span>
            </div>
          ),
          value: filteredOptions[0].value,
        };
      } else {
        return null;
      }
    }
  
    if (!hasReturnedDefaultOption) {
      return filterAndReturnDefaultOption(language);
    } else {
      return null;
    }
  }


  const [defaultValue, setDefaultValue] = useState(null);

  // Call the function to get the default option once
  if (!defaultValue) {
    const defaultOption = getDefaultOptionByLanguage(lang); // Pass "en" or "ar" accordingly
    setDefaultValue(defaultOption);
  }

  const [t,i18n]=useTranslation()



  if (lang === null) {
    localStorage.setItem("lang", "en");
  }

  const [language, setLanguage] = useState(lang);

  console.log(language)

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const handleSelect = (e) => {
    setLanguage(e.value);
    setDirection(e.value);
    // console.log(e.target.value)
    // i18n.changeLanguage(e.target.value);
    document.documentElement.setAttribute('lang', e.value);
    
  };



  return (
    <Box sx={{ flexGrow: 1, marginBottom: "4rem" }}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "white",
          direction: `${lang === "ar" ? "rtl" : "ltr"}`,
        }}
      >
        <Container>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        

            <NewMultiSelect
            data={options &&
              options.map((el) => ({
                ...el,
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={el.imageUrl}
                      alt={el.label}
                      style={{ width: '25px', height: 'auto', marginRight: '12px', marginLeft: '12px' }}
                    />
                    <span style={{color:"black"}}>{el.label}</span>
                  </div>
                ),
                value: el.value,
              }))
            }
            onSelectionChange={(e) => {
              handleSelect(e)
            }}
            defaultValue={defaultValue}     
                 />
          


            <Box style={{ width: "60px", height: "60px" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={Logo}
                alt="logo"
              />
            </Box>


          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;
