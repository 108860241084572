import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import "./dropDownMenu.css";

const DropDownMenu = ({
  label,
  value,
  data,
  name,
  onSelect,
  errors,
  direction,
  loading,
  disabled,
  reset,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // Reset dropdown when 'reset' prop changes
  useEffect(() => {
    if (reset) {
      setSelectedValue(null);
    }
  }, [reset]);

  // Responsive Design
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedValue(selectedOption);
    onSelect(selectedOption.value);
  };

  // Define placeholder text based on direction
  const placeholderText = direction === "en" ? "Select" : "اختار";

  return (
    <section style={{ width: "100%", marginBottom: "10px" }}>
      <div style={{ height: `${match ? "3px" : "12px"}` }}>
        <Typography
          style={{
            fontSize: `${match ? "16px" : "20px"}`,
            marginLeft: "5px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            whiteSpace: "nowrap",
          }}
          title={label}
        >
          {label}
        </Typography>
      </div>
      <br />
      <Select
        value={selectedValue}
        options={data.map((item) => ({
          label:
            direction === "en"
              ? item.menuEN?.valueEN || item.serviceEN || item.clinicEN
              : item.menu?.value || item.service || item.clinic,
          value: item.menuValueId || item.serviceId || item.clinicId,
        }))}
        onChange={handleChange}
        isDisabled={disabled}
        isSearchable // Enable keyboard navigation and searching
        placeholder={placeholderText} 
         menuPortalTarget={document.body} // Attach the menu to the body element
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 99999 }), // Set a high z-index
        }}
      />
      
      {errors && (
        <p
          style={{
            color: "red",
            direction: direction === "ar" ? "rtl" : "ltr",
            fontSize: "13px",
            margin: direction === "ar" ? "5px 10px 0 0" : "5px 0 0 10px",
          }}
        >
          {errors[name]}
        </p>
      )}
    </section>
  );
};

export default DropDownMenu;
